import React from 'react'
import Link from '../Link'

const ContentExcerpt = ({
  heading,
  text,
  button,
  buttonText,
  buttonColour,
}) => {
  return (
    <>
      {heading && (
        <>
          <h2 className="text-xl md:text-4xl font-semibold leading-tight mb-4 md:mb-12">
            {heading}
          </h2>
          <div className="dash mb-6 md:mb-10"></div>
        </>
      )}
      {text && text.html && (
        <div
          className="rte text-sm md:text-base"
          dangerouslySetInnerHTML={{
            __html: text.html,
          }}
        ></div>
      )}
      {button?.uid ||
        (button?.url && (
          <Link
            link={button}
            text={buttonText}
            className={`w-full md:w-auto mt-6 md:mt-10 min-w-content-button ${
              buttonColour === 'Blue' ? 'button-primary' : 'button-secondary'
            }`}
          />
        ))}
    </>
  )
}

export default ContentExcerpt
