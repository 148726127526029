import React from 'react'
import axios from 'axios'
import { withFormik, Form, Field } from 'formik'

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

/** constants */
const formName = 'Enquiry'
const wrapperClass = ''
const msgSuccess = 'Thanks, we’ll be in touch soon.'
const msgError = 'There was an error submitting the form, please try again.'

const Success = () => (
  <div className={wrapperClass}>
    <p className="text-xs md:text-lg xl:text-xl ">{msgSuccess}</p>
  </div>
)

const FormWithError = ({ isSubmitting, errors, values }) => (
  <Form
    className={wrapperClass}
    name={formName}
    data-netlify="true"
    data-netlify-honeypot="bot-field"
  >
    {/* * Required by Netlify Form */}
    <input type="hidden" name="form-name" value={formName} />
    {/* Name */}
    <div className="mb-6">
      <label htmlFor="enquiry-name">
        <span className="block text-xs md:text-lg xl:text-xl mb-2">Name</span>
        <Field
          id="enquiry-name"
          className="input"
          type="text"
          name="name"
          required
        />
      </label>
    </div>
    {/* email */}
    <div className="mb-6">
      <label htmlFor="enquiry-email">
        <span className="block text-xs md:text-lg xl:text-xl  mb-2">Email</span>
        <Field
          id="enquiry-email"
          className="input"
          type="email"
          name="email"
          required
        />
      </label>
    </div>
    {/* Phone */}
    <div className="mb-6">
      <label htmlFor="enquiry-phone">
        <span className="block text-xs md:text-lg xl:text-xl  mb-2">Phone</span>
        <Field
          id="enquiry-phone"
          className="input"
          type="tel"
          name="phone"
          required
        />
      </label>
    </div>
    {/* Message */}
    <div className="mb-8">
      <label htmlFor="enquiry-msg">
        <span className="block text-xs md:text-lg xl:text-xl  mb-2">
          Message
        </span>
        <Field
          id="enquiry-msg"
          component="textarea"
          className="textarea"
          name="message"
          required
        ></Field>
      </label>
    </div>

    {/* Submit Btn */}
    <button
      className="button-primary w-full md:w-auto px-14"
      type="submit"
      aria-label="Send"
      disabled={isSubmitting}
    >
      Send enquiry
    </button>
    {/* Error Message */}
    {errors.submit && <div className="mt-6 ">{msgError}</div>}
  </Form>
)

const DefaultForm = props => {
  const { status } = props
  if (status && status.success) {
    return <Success />
  } else {
    return <FormWithError {...props} />
  }
}

const EnquiryForm = withFormik({
  mapPropsToValues: values => ({
    name: values.name || '',
    email: values.email || '',
    phone: values.phone || '',
    message: values.message || '',
  }),
  validate: values => {
    const errors = {}
    return errors
  },
  handleSubmit: async (
    form,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      await axios.post(
        '?no-cache=1',
        encode({ 'form-name': formName, ...form }),
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      )
      resetForm()
      setStatus({ success: true })
      if (window && window.dataLayer) {
        window.dataLayer.push({
          event: 'enquirySent',
        })
      }
    } catch (error) {
      // Set errors.submit message
      setErrors({ submit: true })
      // Set errors.submit to TRUE
      if (error.response) {
        console.error(error.response)
      } else {
        console.error(error)
      }
      // Erasing error message after 5s
      setTimeout(() => {
        setErrors({ submit: '' })
      }, 20000)
    } finally {
      setSubmitting(false)
    }
  },
})(DefaultForm)

export default EnquiryForm
