import React from 'react'
import PropTypes from 'prop-types'

import { graphql } from 'gatsby'
import { mergePrismicPreviewData } from 'gatsby-source-prismic'
import { Link } from 'gatsby'

import NotFoundPage from 'pages/404'
import Image from 'components/Image'
import Layout from 'components/Layout'
import EnquiryForm from 'components/Form'
import ContentExcerpt from 'components/Content/ContentExcerpt'
import Map from '../components/Map'

const IS_BROWSER = typeof window !== 'undefined'

class ContactPage extends React.Component {
  render() {
    const { location, data: staticData, pageContext } = this.props
    const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__
    const data = mergePrismicPreviewData({ staticData, previewData })

    const contactPage = data.prismicContact.data
    if (contactPage == null) return <NotFoundPage location={location} />

    const {
      breadcrumb: { crumbs },
    } = pageContext

    const button = contactPage.body_button
    const buttonText = contactPage.button_text

    return (
      <Layout
        heroImage={contactPage.hero}
        crumbs={crumbs}
        metaTitle={contactPage.meta_title}
        metaDescription={contactPage.meta_description}
        pageTitle={contactPage.title}
        location={location}
      >
        <div className="container">
          <div className="-mx-8">
            <div className="w-full px-8">
              <div className="relative w-full py-10 xl:py-20 xl:pb-10">
                <ContentExcerpt
                  heading={contactPage.body_heading}
                  text={contactPage.rich_text}
                  button={contactPage.body_button}
                  buttonText={contactPage.button_text}
                  buttonColour={'Blue'}
                />
              </div>
            </div>
            {/* <div className="w-full lg:flex justify-end px-8 bg-flush-right bg-sand">
              <div className="relative w-full md:w-10/12 lg:w-full xl:w-10/12 py-10 xl:py-22 z-20">
                <div className="flex flex-wrap md:block mb-8 md:mb-12 items-center justify-center">
                  {contactPage.contacts.map((item, index) => (
                    <div
                      className={`w-1/2 last:w-full md:w-full md:flex items-center text-center md:text-left mb-8 last:mb-0 ${
                        index === contactPage.contacts.length - 1
                          ? 'w-full md:w-1/2'
                          : ''
                      }`}
                      key={index}
                    >
                      <div className="flex-shrink-0 flex items-center justify-center w-15 h-15 md:w-18 md:h-18 xl:w-22 xl:h-22 rounded-full bg-white p-4 xl:p-5 mx-auto md:ml-0 md:mr-5 xl:mr-7 mb-2 md:mb-0">
                        <img
                          className="w-full"
                          src={
                            item.contact_icon && item.contact_icon.localFile
                              ? item.contact_icon.localFile.url
                              : item.contact_icon.url
                          }
                          alt={item.contact_icon.alt}
                        />
                      </div>
                      {item.contact_text.html && (
                        <div
                          className="text-base md:text-lg xl:text-xl leading-tight rte"
                          dangerouslySetInnerHTML={{
                            __html: item.contact_text.html,
                          }}
                        ></div>
                      )}
                    </div>
                  ))}
                  {data.prismicGlobals.data.booking_button.url && (
                    <div className="w-full text-center md:text-left items-center">
                      <a
                        className="button-secondary mt-2 px-6 md:px-12 lg:px-16"
                        href={data.prismicGlobals.data.booking_button.url}
                      >
                        Make a booking
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="relative contact">
          <div className="container ">
            <div className="flex flex-wrap -mx-8">
              <div className="w-full lg:w-1/2 px-8 relative">
                <div className="relative w-full md:w-10/12 lg:w-full xl:w-10/12 py-10 xl:py-20 z-20">
                  <h2 className="text-xl md:text-4xl leading-none mb-4 md:mb-10">
                    Send us an enquiry
                  </h2>
                  <EnquiryForm />
                </div>
              </div>
              <div className="w-full lg:w-1/2 lg:flex justify-end px-8 z-30">
                <div className="relative w-full md:w-10/12 lg:w-full xl:w-10/12 py-10 xl:py-20">
                  {contactPage.emails_heading && (
                    <>
                      <h2 className="text-xl md:text-4xl leading-none mb-4 md:mb-10">
                        {contactPage.emails_heading}
                      </h2>
                    </>
                  )}
                  {contactPage.emails && (
                    <ul>
                      {contactPage.contacts.map((item, index) => (
                        <li key={index} className="mb-8 last:mb-0">
                          {item?.contact_text?.text && (
                            <div>
                              <p>Call us on:</p>
                              <a
                                className="link-alt text-base xl:text-lg font-semibold"
                                href={`tel:${item.contact_text.text}`}
                              >
                                {item.contact_text.text}
                              </a>
                            </div>
                          )}
                        </li>
                      ))}
                      {contactPage.emails.map((item, index) => (
                        <li key={index} className="mb-8 last:mb-0">
                          {item.email && (
                            <div>
                              <p>Email us at:</p>
                              <a
                                className="link-alt text-base xl:text-lg font-semibold"
                                href={`mailto:${item.email}`}
                              >
                                {item.email}
                              </a>
                            </div>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                  <Link
                    className="button-secondary px-10 button-small"
                    to={`/`}
                  >
                    {'Make a booking'}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* 
        <div className="relative pt-5 pb-56 md:py-32">
          <div className="container relative z-20">
            <div className="lg:w-1/2 xl:w-1/3 bg-white">
              <div className="px-5 pt-5 md:pt-8 md:px-8 lg:pt-15 lg:px-15">
                {contactPage.location_heading && (
                  <>
                    <h2 className="text-xl md:text-2xl lg:text-4xl font-semibold leading-none mb-6 md:mb-8 lg:mb-10">
                      {contactPage.location_heading}
                    </h2>
                    <div className="dash mb-6 lg:mb-10"></div>
                  </>
                )}
                {data.prismicGlobals.data.address && (
                  <ul className="text-lg md:text-xl lg:text-2xl font-semibold leading-tight mb-6">
                    {data.prismicGlobals.data.address
                      .split('\n')
                      .map((item, index) => {
                        return <li key={index}>{item}</li>
                      })}
                  </ul>
                )}
              </div>
              <div className="md:px-8 md:pb-8 lg:px-15 lg:pb-15">
                {contactPage.location_button && (
                  <a
                    className="w-full md:w-auto button-primary px-12"
                    href={contactPage.location_button.url}
                    target={
                      contactPage.location_button.link_type === 'Web'
                        ? '_blank'
                        : ''
                    }
                    rel="noopener noreferrer"
                  >
                    {contactPage.button_text2 && contactPage.button_text2}
                  </a>
                )}
              </div>
            </div>
          </div>

          <div className="absolute inset-0">
            {contactPage.location_hero.localFile ||
            contactPage.location_hero.url ? (
              <Image
                className="w-full h-full"
                fluid={
                  contactPage.location_hero.localFile?.childImageSharp?.fluid
                }
                src={contactPage.location_hero.url}
                alt={contactPage.location_hero.alt}
              />
            ) : (
              <div className="w-full h-full bg-teal-opaque-75"></div>
            )}
          </div>
        </div> */}
        <Map />
      </Layout>
    )
  }
}

ContactPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export const pageContactQuery = graphql`
  query {
    bgImage: file(relativePath: { eq: "contact-bg.jpg" }) {
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    prismicGlobals {
      data {
        booking_button {
          url
        }
        heading {
          text
        }
        address1 {
          text
        }
        subheading {
          text
        }
        address
        show_alert
        alert_heading {
          text
        }
        alert_text {
          text
        }
      }
    }
    prismicContact {
      data {
        meta_title
        meta_description
        title
        rich_text {
          html
        }
        location_heading
        location_hero {
          localFile {
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          alt
        }
        location_button {
          url
          uid
          link_type
        }
        body_button {
          url
          uid
          link_type
        }
        body_heading
        button_text
        button_text1
        button_text2
        contacts {
          contact_icon {
            url
            localFile {
              url
              childImageSharp {
                fluid(quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          contact_text {
            html
            text
          }
        }
        contacts_button {
          url
          uid
          link_type
        }
        emails {
          email
          name
        }
        emails_heading
        hero {
          alt
          localFile {
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  }
`

export default ContactPage
