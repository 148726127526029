import React, { useState, useCallback } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import mapStyle from './mapStyle.json' // Import your JSON file

const containerStyle = {
  width: '100%',
  height: '650px',
}

const center = {
  lat: -45.030213835006094, // Example coordinates (Auckland, NZ)
  lng: 168.66286288318864,
}

const Map = () => {
  const [map, setMap] = useState(null)
  const [googleLoaded, setGoogleLoaded] = useState(false)

  const onLoad = useCallback(map => {
    setMap(map)
    if (window.google) {
      setGoogleLoaded(true) // Set Google Maps API as ready
    }
  }, [])

  const data = useStaticQuery(graphql`
    {
      prismicGlobals {
        data {
          subheading {
            text
          }
          address
          show_alert
          alert_heading {
            text
          }
          alert_text {
            text
          }
          address1 {
            text
            html
          }
          heading {
            text
          }
        }
      }
    }
  `)

  const {
    subheading,
    address1,
    show_alert,
    alert_text,
    alert_heading,
    heading,
  } = data.prismicGlobals.data

  return (
    <div className="map">
      <LoadScript
        googleMapsApiKey="AIzaSyCXbrEDcuw08CLQ1PYR763F_z5X7U0Eqik"
        onLoad={() => setGoogleLoaded(true)}
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={16}
          options={{
            styles: mapStyle, // Apply custom styles
            disableDefaultUI: true, // Optional: Hide default controls
          }}
          onLoad={onLoad}
        >
          {googleLoaded && (
            <Marker
              position={center}
              icon={{
                url:
                  'data:image/svg+xml;charset=UTF-8,' +
                  encodeURIComponent(`
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
            <path fill="none" d="M0 0h16v16H0z"></path>
            <path fill="#11B9BC" d="M8 0C4.688 0 2 2.688 2 6c0 6 6 10 6 10s6-4 6-10c0-3.312-2.688-6-6-6m0 8a3.001 3.001 0 0 1 0-6 3.001 3.001 0 0 1 0 6"></path>
          </svg>
        `),
                scaledSize: googleLoaded
                  ? new window.google.maps.Size(40, 40)
                  : null, // Check before using
              }}
            />
          )}
        </GoogleMap>
      </LoadScript>
      <div className="map__overlay container">
        <div className="map__box">
          <h3>{heading.text}</h3>
          <h5>{subheading.text}</h5>
          <p dangerouslySetInnerHTML={{ __html: address1.html }} />
          <a
            className="w-full md:w-auto button-primary px-12"
            href={'https://maps.app.goo.gl/md5cz4d2zrr95mvQ8'}
            target={'_blank'}
            rel="noopener noreferrer"
          >
            Get Directions
          </a>
        </div>
      </div>
      {show_alert && (
        <div className="map__alert container">
          <div className="map__alert_wrap ">
            <h3>{alert_heading.text}</h3>
            <p>{alert_text.text}</p>
          </div>
        </div>
      )}
    </div>
  )
}

export default Map
